import './App.css';
import HomePage from './pages/HomePage/HomePage';
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
import GalleryPage from './pages/GalleryPage/GalleryPage';

function Layout(props) {
  return <Outlet/>;
}

function App() {
  return (
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout/>}>
              <Route index element={<HomePage/>}/>
              <Route path="gallery/:galleryId" element={<GalleryPage/>}/>
              <Route path="*" element={<div>404</div>}/>
            </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;

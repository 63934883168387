import React from 'react';
import cl from './HomePage.module.css';
import logoMoscow from '../../img/edelweissLogo/edelweiss_mc_moscow.png';
import logoMskOutskirts
  from '../../img/edelweissLogo/edelweiss_mc_msk_outskirts.png';
import emblem from '../../img/edelweissLogo/emblem.png';
import main from '../../img/edelweissLogo/main.jpg';
import cn from 'classnames';
import logoRussia from '../../img/edelweissLogo/edelweiss_mc_russia.png';
import {NavLink} from 'react-router-dom';

const galleryLinks = [
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  'events'];

const chapters = [
  [logoMoscow, 'Edelweiss Moscow logo'],
  [emblem, 'Emblem'],
  [logoMskOutskirts, 'Edelweiss MSK outskirts logo'],
];

const HomePage = () =>
    (
        <main className={cl.page}>
          <section className={cl.section}>
            <div className={cl.logo__outer}>
              <img className={cn(cl.logo, cl.image)} src={logoRussia}
                   alt="Edelweiss Russia logo"/>
            </div>

            <nav className={cl.navbar}>
              <ul className={cn(cl.list, cl.navbar__list)}>
                <a href="#description" className={cn(cl.link, cl.navbar__link)}>
                  <li><h2>О&#160;НАС</h2></li>
                </a>
                <a href="#chapters" className={cn(cl.link, cl.navbar__link)}>
                  <li><h2>ЧАПТЕРЫ</h2></li>
                </a>
                <a href="#gallery" className={cn(cl.link, cl.navbar__link)}>
                  <li><h2>ГАЛЕРЕЯ</h2></li>
                </a>
              </ul>
            </nav>
          </section>

          <section className={cn(cl.section, cl.descriptionSection)}
                   id="description">
            <img src={main} alt='Мотоклуб "Edelweiss МС"'
                 className={cl.image}/>
            <article className={cl.descriptionSection__article}>
              <p className={cl.descriptionSection__text}>Мотоклуб «Edelweiss»
                основан в 2013-м году в Москве.</p>
              <p className={cl.descriptionSection__text}>С 14 апреля 2018-го
                года Edelweiss MC Moscow</p>
              <p className={cl.descriptionSection__text}>2 ноября 2019-го года
                открыт Чаптер Edelweiss MC Moscow
                Outskirts.</p>
              <p className={cl.descriptionSection__text}>1 августа 2022-го года
                открыт
                Проспект Чаптер Edelweiss MC Moscow South-West.
              </p>
            </article>
          </section>

          <section className={cn(cl.section, cl.chaptersSection)}
                   id="chapters">
            <div className={cl.chaptersSection__images}>
              {chapters.map(chapter =>
                  <div className={cl.chaptersSection__container}>
                    <img src={chapter[0]} alt={chapter[1]}
                         className={cl.chaptersSection__image}/>
                  </div>,
              )}
            </div>
          </section>

          <section className={cn(cl.section, cl.gallerySection)} id="gallery">
            <h2 className={cl.title}>Фотогалерея</h2>
            <ul className={cl.list}>
              {galleryLinks.map(link =>
                  <NavLink className={cl.link} to={`gallery/${link}`}>
                    <li className={cl.gallerySection__item}>{link}</li>
                  </NavLink>)}
            </ul>
          </section>
        </main>
    );

export default HomePage;

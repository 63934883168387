import React, {useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import cl from './GalleryPage.module.css';
import logoRussia from '../../img/edelweissLogo/edelweiss_mc_russia.png';
import {yearToImages} from '../../utils';

const GalleryPage = () => {
  const {galleryId} = useParams();
  const images = yearToImages[galleryId];
  const [bigImage, setBigImage] = useState(null)

  return (
      <main className={cl.page}>
        <NavLink to="/" className={cl.logo__outer}>
          <img className={cl.logo}
               src={logoRussia}
               alt="Edelweiss Russia logo"/>
        </NavLink>
        <div className={cl.gallery}>
          {images.map(
              img => <img src={'/photos/' + img} className={cl.gallery__image}
                          key={img}
                          onClick={() => {
                            setBigImage(img.replace('.jpg', '_b.jpg'))
                          }}
                          alt=""/>)}
        </div>
        <NavLink to="/"><h3>Вернуться</h3></NavLink>
        {bigImage && <img src={`/photos/${bigImage}`} alt={''}/>}
      </main>
  );
};

export default GalleryPage;
